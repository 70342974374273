import '../../styles/pages/fall-winter-2020/becoming-christmas-karen.scss';

import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/fall-winter-2020-stories/more-stories"
import StoryNav from "../../components/fall-winter-2020-stories/story-nav"

import Hero from '../../images/fall-winter-2020/becoming-christmas-karen/hero-image.jpg';

import socialBanner from "../../images/fall-winter-2020/social-banners/becoming-christmas-karen.jpg";


export default class Index extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var pageTitle = 'Becoming ‘Christmas Karen’';
    var pageDesc = 'This holiday season, alumna Karen Schaler ’87 is spreading hope through feel-good books and movies.';


    var heroStyles = {
      backgroundColor: '#222',
      backgroundImage: `url(${Hero})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      color: 'white'
    }

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="fall-winter-2020">
        <Helmet>
          <title>{pageTitle}</title>
          <body className="becoming-christmas-karen" />


          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>
        <div className="hero story animated fadeIn" style={heroStyles}>
          <div className="text">
            <h1 className="animated fadeInDown delay-1s">{pageTitle}</h1>
            <p className="sub-heading animated fadeInDown delay-1s">During tough times, a Titan alumna spreads hope through feel-good holiday stories.</p>
            <p className="author animated fadeInUp delay-2s">By Karen Lindell / Photos Courtesy of Karen Schaler</p>
          </div>
          <div className="tint"></div>
        </div>
        <article className="wrap small">

          <ScrollAnimation animateIn="fadeIn">
          <p><span className="intro-text">With her birthday</span> on Dec. 19 and a great aunt legally named Merry Christmas Day, it may not be too surprising that Cal State Fullerton alumna Karen Schaler ’87 — a.k.a. “Christmas Karen” — has made a career out of spreading Yuletide cheer through feel-good holiday stories.</p>

          <aside className="related-stories">

            <h2>Christmas Karen’s Favorite Christmas Shows</h2>
            <p>Classic Christmas movies have inspired Schaler, and the shows that she enjoys watching are those filled with heart, hope and the belief that anything is possible at Christmas.</p>

            <ul>
              <li>“Miracle on 34th Street”</li>
              <li>“It’s a Wonderful Life”</li>
              <li>“How the Grinch Stole Christmas” (1966 original)</li>
              <li>“The Holiday”</li>
              <li>“Love Actually”</li>
              <li>“Last Holiday”</li>
              <li>“Jack Frost”</li>
              <li>“Elf”</li>
              <li>Season four finale of “Schitt’s Creek” (“Merry Christmas, Johnny Rose”)</li>
            </ul>

          </aside>
          
          <p>Schaler’s original screenplay for “A Christmas Prince” (2017) became an overnight sensation for Netflix, spawning two sequels. She followed with her original “Christmas Camp” movie for Hallmark, two Lifetime Christmas movies and four Christmas books — all within three years.</p>

          <p>The double major in communications- radio/TV/film and sociology then brought the magic of her books and movies to life, holding “Christmas Camp” getaways in Arizona that feature culinary and wine tasting classes, holiday crafts and activities, movie nights and “Christmas Karen” chats.</p>

          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <p><strong>How did you go from being a war correspondent to “Christmas Karen”?</strong></p>

          <p>After graduating from CSUF, I worked as a TV anchor and reporter in nine different markets. I won my first Emmys for the documentaries I produced and reported as the first correspondent embedded with troops in Bosnia (1995) and Afghanistan (2007). After Afghanistan, wanting to tell more uplifting and empowering stories, I quit TV news and created a Travel Therapy book and TV series. When a health issue forced me to slow down, I wrote my first Christmas movie, “A Christmas Prince.” As I wrote more holiday movies and books, the press nicknamed me “Christmas Karen.”</p>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <p><strong>How did your Cal State Fullerton experience inform your career?</strong></p>

          <p>I credit Cal State Fullerton with teaching me to persevere and pivot quickly, which is something I’ve done throughout my career, and especially now during the pandemic. At CSUF, I found my true passion in the late Ronald Dyas’ screenwriting class. Dyas was a tough professor and realistic about the challenges of a screenwriting career, but he empowered us to try. He saw something in me and said I had a knack for screenwriting. When he gave me an A-, it helped me believe I could do what I loved, and I never looked back.</p>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <p><strong>How has the pandemic changed your work?</strong></p>

          <p>The pandemic changed everything. I had to suddenly leave New York City and address devastating health issues with family and friends. Professionally, all my projects were put on hold. What keeps me going is knowing the stories I write are uplifting. I’ve had people reach out saying they’re watching my movies and reading my books because it brings them some peace and comfort, and that’s the best compliment I could ever get right now.</p>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <p><strong>What are your current and upcoming projects?</strong></p>

          <p>My fans have been asking for two things: more chats like those at Christmas Camp, and my next Christmas book. I launched a new show called “Christmas Karen: Behind the Story,” where I talk to the writers, directors, actors and producers who help create TV Christmas movies. They share their own personal journeys, inspiring tips and advice. My book “Christmas Ever After” just published, and to make that happen, I had to start my own publishing company. Learning a new industry has been intense, but now I can donate part of the proceeds from each book sale to help support libraries and independent bookstores.</p>

          <p>For Christmas Camp, we’ll be offering a few safe, modified versions in 2020, and we’re already planning for 2021. I’m also in development for my next Christmas movie and writing the movie version of “Christmas Ever After.”</p>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <p><strong>How do you think this holiday season will be amid the pandemic?</strong></p>

          <p>The holidays this year are going to be more important than ever to give us hope. Whether you’re celebrating Christmas, Hanukkah, Las Posadas, Kwanzaa or the Chinese New Year, I believe the holiday season is universal in how it celebrates and embraces family, friends, faith, community, hope and happiness. The holidays bring us together, and even if we can’t physically see each other, we’re together in our hearts and memories. It’s a comfort to know we’re not alone — we’re connected throughout the season and always. <span className="end-mark" /></p>
          </ScrollAnimation>

        
          <ScrollAnimation animateIn="fadeIn">
          <p className="learn-more">Support the College of Communications<br /><a href="https://campaign.fullerton.edu/comm" target="_blank">campaign.fullerton.edu/comm</a></p>
          </ScrollAnimation>

        </article>
        <MoreStories slug="becoming-christmas-karen" />
        <StoryNav slug="becoming-christmas-karen" />
      </Layout>
    );
  }
} 